import { useContext } from 'react';
import { TemplatesContext } from '../../template-context';

export const useStyle = () => {
	const { website } = useContext(TemplatesContext);
	const settings = website.content.siteComponents.footer.settings;
	const sharedStatus = website.content.siteComponents.footer.shared;
	const sharedContent = website.content.siteComponents.shared.settings;

	return {
		footerColorIndex: settings.footerColor > -1 ? settings.footerColor : 3,
		linkSeparatorColorIndex: settings.linkSeparatorColor > -1 ? settings.linkSeparatorColor : 0,
		powerBarColorIndex: settings.powerBarColor > -1 ? settings.powerBarColor : 0,
		socialLinksColorIndex:
			typeof sharedStatus.socialLinksColor !== 'undefined' && !sharedStatus.socialLinksColor
				? settings.socialLinksColor > -1 ? settings.socialLinksColor : 0
				: sharedContent.socialLinksColor > -1 ? sharedContent.socialLinksColor : 0
	};
};
