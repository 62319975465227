import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const TemplatesContext = createContext();

const TemplatesProvider = (props) => {
	const websiteWithDefaults = {
		...props.website,
		content: {
			siteHtml: {},
			...props.website.content
		}
	};

	const [ mode ] = useState(props.mode);
	const [ page, setPage ] = useState(props.page);
	const [ website, setWebsite ] = useState(websiteWithDefaults);

	useEffect(
		() => {
			setPage(props.page);
		},
		[ props.page ]
	);

	useEffect(
		() => {
			setWebsite(websiteWithDefaults);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[ props.website ]
	);

	return website && page && <TemplatesContext.Provider value={{ mode, page, website }}>{props.children}</TemplatesContext.Provider>;
};

TemplatesProvider.propTypes = {
	children: PropTypes.node,
	mode: PropTypes.number.isRequired,
	page: PropTypes.object.isRequired,
	website: PropTypes.object.isRequired
};

export default TemplatesProvider;
