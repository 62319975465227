import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapPin from './map-pin.png';
import './map.css';

export const Map = ({ data }) => {
	return (
		<div className="map-box">
			{data.content.lat && data.content.long && <MapboxGLMap lat={Number(data.content.lat)} long={Number(data.content.long)} />}
		</div>
	);
};

Map.propTypes = {
	data: PropTypes.object.isRequired
};

const MapboxGLMap = ({ lat, long }) => {
	//Force min and max values of Lat/Lon
	lat = lat < -90 ? -90 : lat;
	lat = lat > 90 ? 90 : lat;
	long = long < -180 ? -180 : long;
	long = long > 180 ? 180 : long;

	const [ map, setMap ] = useState(null);
	const mapContainer = useRef(null);

	useEffect(
		() => {
			if (map) {
				map.removeLayer('points');
				map.removeSource('point');
				map.addSource('point', {
					type: 'geojson',
					data: {
						type: 'FeatureCollection',
						features: [
							{
								type: 'Feature',
								geometry: {
									type: 'Point',
									coordinates: [ long, lat ]
								}
							}
						]
					}
				});
				map.addLayer({
					id: 'points',
					type: 'symbol',
					source: 'point',
					layout: {
						'icon-image': 'pin',
						'icon-size': 1
					}
				});
				map.setCenter([ long, lat ]);
				map.setZoom(14);
			} else {
				loadMap(map, lat, long);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[ lat, long ]
	);

	useEffect(
		() => {
			loadMap(map, lat, long);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const loadMap = useCallback((map, lat, long) => {
		if (!isNaN(lat) && !isNaN(long)) {
			mapboxgl.accessToken = process.env.MAPBOX_ACCESS_TOKEN;
			const initializeMap = ({ setMap, mapContainer }) => {
				const map = new mapboxgl.Map({
					container: mapContainer.current,
					style: 'mapbox://styles/mapbox/streets-v11',
					center: [ long, lat ],
					zoom: 14,
					attributionControl: false
				});

				map.on('load', () => {
					map.loadImage(MapPin, function(error, image) {
						if (error) throw error;
						map.addImage('pin', image);
						map.addSource('point', {
							type: 'geojson',
							data: {
								type: 'FeatureCollection',
								features: [
									{
										type: 'Feature',
										geometry: {
											type: 'Point',
											coordinates: [ long, lat ]
										}
									}
								]
							}
						});
						map.addLayer({
							id: 'points',
							type: 'symbol',
							source: 'point',
							layout: {
								'icon-image': 'pin',
								'icon-size': 1
							}
						});
					});

					setMap(map);
					map.resize();
				});
			};

			if (!map) initializeMap({ setMap, mapContainer });
		}
	}, []);

	return (
		<div
			ref={(el) => (mapContainer.current = el)}
			style={{
				width: '100%',
				height: '100%'
			}}
		/>
	);
};

MapboxGLMap.defaultProps = {
	lat: 0,
	long: 0
};

MapboxGLMap.propTypes = {
	lat: PropTypes.number,
	long: PropTypes.number
};
