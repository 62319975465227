import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TemplatesContext } from '../../template-context';
import Iframe from 'react-iframe';

export const Document = ({ data }) => {
	const { website } = useContext(TemplatesContext);

	return (
		data.content.pdfFileName && (
			<Iframe
				url={`${process.env.BLOB_STORAGE_URL}/${website.websiteId}/${data.content.pdfFileName}`}
				width="100%"
				height="700px"
				id="pdf-iframe"
				display="initial"
				position="relative"
			/>
		)
	);
};

Document.propTypes = {
	data: PropTypes.object.isRequired
};
