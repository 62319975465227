import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useTheme } from '../../templates/common/themes';
import { useStyle } from './useStyle';
import './power-bar.css';

export const PowerBar = () => {
	const styles = useStyle();
	const powerBarStyles = useTheme({ colorIndex: styles.powerBarColorIndex });

	return (
		<Container fluid style={powerBarStyles.containerStyles}>
			<Row className="power-bar">
				<Col xs="12">
					<div>
						&copy; {new Date().getFullYear()}. Thomson Directories Ltd. The content of this website is owned by us; copying of any content
						(including images) without our consent is in breach of our <a href="https://www.thomsonlocal.com/onlinemediaterms">T&Cs</a> | All rights
						Reserved
					</div>
					<div className="powered-by mt-4">
						<div className="text">Powered by </div>
						<a href="https://www.thomsonlocal.com">
							<div className="logo icon" />
						</a>
					</div>
				</Col>
			</Row>
		</Container>
	);
};
