import React from 'react';
import PropTypes from 'prop-types';

export const Wysiwyg = ({ data }) => {
	return <div dangerouslySetInnerHTML={{ __html: data.content.userContent || '' }} />;
};

Wysiwyg.propTypes = {
	data: PropTypes.object.isRequired
};
