import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '../../templates/common/themes';
import './contact-details.css';

export const ContactDetails = ({ data }) => {
	const theme0 = useTheme({ colorIndex: data.settings.themeColorIndex > -1 ? data.settings.themeColorIndex : 0 });

	const getContactTypeClass = (typeNumber) => {
		switch (typeNumber) {
			case '0':
				return 'phone';
			case '1':
				return 'mobile';
			case '2':
				return 'fax';
			case '3':
				return 'email';
			case '4':
				return 'address';
		}
	};

	const iconBoxStyle = {
		borderRadius: data.settings.borderRadius ? `${data.settings.borderRadius}%` : '0%',
		...theme0.containerStyles
	};

	return (
		<div className="contact-details-list">
			{data.content.details.length > 0 &&
				data.content.details.map((item, index) => (
					<div key={index} className="contact-detail d-flex">
						<div style={iconBoxStyle} className="iconbox"> 
							<div className={`icon ${getContactTypeClass(item.contactType)} ${data.settings.darkIcons ? 'dark' : ''}`} />
						</div>
						<div>
							{item.contactDetail && getContactTypeClass(item.contactType) === 'phone' && <a href={`tel: ${item.contactDetail}`}>{item.contactDetail}</a>}
							{item.contactDetail && getContactTypeClass(item.contactType) === 'mobile' && <a href={`tel: ${item.contactDetail}`}>{item.contactDetail}</a>}
							{item.contactDetail && getContactTypeClass(item.contactType) === 'fax' && <p>{item.contactDetail}</p>}
							{item.contactDetail && getContactTypeClass(item.contactType) === 'email' && <a href={`mailto: ${item.contactDetail}`}>{item.contactDetail}</a>}
							{item.contactDetail && getContactTypeClass(item.contactType) === 'address' && <p>{item.contactDetail}</p>}
						</div>
					</div>
				))}
		</div>
	);
};

ContactDetails.propTypes = {
	data: PropTypes.object.isRequired
};
