import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TemplatesContext } from '../../template-context';

export const Image = ({ data }) => {
	const { website } = useContext(TemplatesContext);

	return data.settings.imagePath ? (
		<img className="w-100" src={`${process.env.BLOB_STORAGE_URL}/${website.websiteId}/${data.settings.imagePath}`} alt={data.settings.imageAltText} />
	) : null;
};

Image.propTypes = {
	data: PropTypes.object.isRequired
};
