import React, { useState, useContext } from 'react';
import { slide as Menu } from 'react-burger-menu';
import TemplateLink from '../../templates/common/template-link';
import { TemplatesContext } from '../../template-context';
import { useTheme } from '../../templates/common/themes';

const MenuMobile = () => {
	const { website } = useContext(TemplatesContext);
	const [ menuIsOpen, setMenuIsOpen ] = useState(false);
	const theme1 = useTheme({
		colorIndex: website.content.siteComponents.header.settings.color > -1 ? website.content.siteComponents.header.settings.color : 1
	});
	const theme3 = useTheme({
		colorIndex: website.content.siteComponents.header.settings.menuColor > -1 ? website.content.siteComponents.header.settings.menuColor : 3
	});

	const handleStateChange = (state) => {
		setMenuIsOpen(state.isOpen);
	};

	const closeMenu = () => {
		setMenuIsOpen(false);
	};

	const styles = {
		bmBurgerButton: {
			position: 'absolute',
			width: '36px',
			height: '30px',
			right: '36px',
			top: '28px'
		},
		bmBurgerBars: {
			background: `rgb(${theme1.secondaryColors.red},${theme1.secondaryColors.green},${theme1.secondaryColors.blue})`
		},
		bmBurgerBarsHover: {
			opacity: '0.7'
		},
		bmCrossButton: {
			height: '24px',
			width: '24px'
		},
		bmCross: {
			background: `rgb(${theme3.secondaryColors.red},${theme3.secondaryColors.green},${theme3.secondaryColors.blue})`
		},
		bmMenuWrap: {
			position: 'fixed',
			height: '100%',
			top: '0px'
		},
		bmMenu: {
			background: `rgb(${theme3.primaryColors.red},${theme3.primaryColors.green},${theme3.primaryColors.blue})`,
			padding: '2.5em 1.5em 0',
			fontSize: '1.15em'
		},
		bmMorphShape: {
			fill: `rgb(${theme3.primaryColors.red},${theme3.primaryColors.green},${theme3.primaryColors.blue})`
		},
		bmItemList: {
			color: `rgb(${theme3.secondaryColors.red},${theme3.secondaryColors.green},${theme3.secondaryColors.blue})`,
			padding: '0.8em'
		},
		bmItem: {
			display: 'block',
			width: '100%',
			padding: '10px',
			outline: 'none',
			borderBottom: 'solid 1px'
		},
		bmOverlay: {
			background: 'rgba(0, 0, 0, 0.3)',
			top: '0px'
		}
	};

	return (
		<div className="burger-menu d-block d-md-none">
			<Menu right isOpen={menuIsOpen} onStateChange={(state) => handleStateChange(state)} styles={styles}>
				{website.content.pageTemplates.map(
					(item, index) =>
						!item.hideLinkInHeader &&
						(item.type === 'page'
							? item.path && (
									<div key={index} onClick={closeMenu}>
										<TemplateLink linkPath={item.path} linkName={item.pageName} />
									</div>
								)
							: item.pages.map((item, index) => (
									<div key={index} onClick={closeMenu}>
										<TemplateLink key={index} linkPath={item.path} linkName={item.pageName} />
									</div>
								)))
				)}
			</Menu>
		</div>
	);
};

export default MenuMobile;
