import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '../../templates/common/themes';

export const TickList = ({ data }) => {
	const theme0 = useTheme({ colorIndex: data.settings.themeColorIndex > -1 ? data.settings.themeColorIndex : 0 });

	const tickStyle = {
		height: '30px',
		width: '30px',
		lineHeight: '30px',
		flex: 'none',
		textAlign: 'center',
		marginRight: '10px',
		borderRadius: data.settings.borderRadius ? `${data.settings.borderRadius}%` : '0%',
		...theme0.containerStyles
	};

	return (
		data.content.tickItems.length > 0 &&
		data.content.tickItems.map((item, index) => (
			<div key={index} className="d-flex">
				<div style={tickStyle}>
					<b>&#10003;</b>
				</div>
				<div>
					{item.title && <h4 className="mb-1">{item.title}</h4>}
					{item.text && <p>{item.text}</p>}
				</div>
			</div>
		))
	);
};

TickList.propTypes = {
	data: PropTypes.object.isRequired
};
