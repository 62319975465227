import React, { useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { TemplatesContext } from '../../template-context';
import SocialLinks from '../shared/social-links';
import TemplateLink from '../../templates/common/template-link';
import './defaultfooter.css';
import { useTheme } from '../../templates/common/themes';
import { useContent } from './useContent';
import { useStyle } from './useStyle';
import { PowerBar } from './power-bar';

export const DefaultFooter = () => {
	const { website } = useContext(TemplatesContext);
	const footer = useContent();
	const styles = useStyle();
	const footerStyles = useTheme({ colorIndex: styles.footerColorIndex });
	const linkSeparatorStyles = useTheme({ colorIndex: styles.linkSeparatorColorIndex });

	const footerLinkWrapperStyle = {
		borderBottom: `1px solid rgb(${linkSeparatorStyles.primaryColors.red},${linkSeparatorStyles.primaryColors.green}, ${linkSeparatorStyles.primaryColors
			.blue})`
	};

	return (
		<footer>
			<Container fluid className="page-content-section" style={footerStyles.layoutContainerStyles}>
				<Row>
					<Col xs="12" md="6">
						{footer.companyName && <h3>{footer.companyName}</h3>}

						<div>
							{footer.phone && (
								<div>
									Telephone:{' '}
									<a href={`tel: ${footer.phone}`} className="phone-link font-weight-bold">
										{footer.phone}
									</a>
								</div>
							)}

							{footer.email && (
								<div>
									Email: <span className="font-weight-bold"><a className="email" href={`mailto: ${footer.email}`}>{footer.email}</a></span>
								</div>
							)}

							{footer.address && (
								<div>
									Address: <span className="font-weight-bold">{footer.address.replace(/\n/g, ', ')}</span>
								</div>
							)}
						</div>

						<div className="mt-4">
							<SocialLinks
								socialLinkInstagram={footer.socialLinkInstagram}
								socialLinkFacebook={footer.socialLinkFacebook}
								socialLinkLinkedIn={footer.socialLinkLinkedIn}
								socialLinkTwitter={footer.socialLinkTwitter}
								socialLinkPinterest={footer.socialLinkPinterest}
								socialLinkYoutube={footer.socialLinkYoutube}
								themeColorIndex={styles.socialLinksColorIndex}
							/>
						</div>
					</Col>
					<Col xs="12" md="4" className="page-links">
						<h3>Site Pages</h3>

						{/* Only show single page links */}
						{website.content.pageTemplates.filter((item) => item.type === 'page').map(
							(link, index) =>
								link.path && (
									<div key={index} style={footerLinkWrapperStyle}>
										<TemplateLink linkPath={link.path} linkName={link.pageName} />
									</div>
								)
						)}
					</Col>
				</Row>
			</Container>
			<PowerBar />
		</footer>
	);
};
