import React, { useContext, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TemplatesContext } from '../template-context';
import { Header } from './header';
import { Footer } from './footer';
import GdprNoticeBar from './gdpr/notice-bar';
import { Container, Row, Col } from 'reactstrap';
import { useTheme } from '../templates/common/themes';
import './layout-components.css';
import { ContentComponent } from './content-component';

export const LayoutComponents = () => {
  const { website, page } = useContext(TemplatesContext);

  //Add tlc reviews widget if required
  useEffect(() => {
    if (website.content.siteHtml.tlcReviewsWidgetOn) {
      const script = document.createElement('script');

      script.id = 'tlc-reviews-widget-script';
      script.src = 'https://adcentre.thomsonlocal.com/Scripts/Widgets/reviewswidget-min.js';
      script.async = true;

      document.body.appendChild(script);
    }
  }, [website.content.siteHtml.tlcReviewsWidgetOn]);

  return (
    <Fragment>
      {/* GDPR Notice Bar */}
      {website.content.siteComponents && website.content.siteComponents.gdpr && <GdprNoticeBar />}

      {/* Header */}
      {website.content.siteComponents && website.content.siteComponents.header && <Header componentId={website.content.siteComponents.header.componentId} />}

      {/* Content */}
      {page.components && page.components.length > 0 && page.components.map((item, index) => <Layout key={index} component={item} website={website} />)}

      {/* Footer */}
      {website.content.siteComponents && website.content.siteComponents.footer && <Footer componentId={website.content.siteComponents.footer.componentId} />}

      {/* Widgets */}
      {website.content.siteHtml && website.content.siteHtml.tlcReviewsWidgetOn && (
        <div id="tlc-reviews-widget" data-key={`${website.customerNumber}`} data-position="right" />
      )}
    </Fragment>
  );
};

export const Layout = ({ component, website }) => {
  let { layoutContainerStyles } = useTheme({
    colorIndex: component.settings.themeColorIndex,
    layoutPaddingTopOn: component.settings.paddingTopOn,
    layoutPaddingBottomOn: component.settings.paddingBottomOn,
  });

  return (
    <div id="layout-component">
      {component.settings.imagePath && (
        <img
          className="container-image"
          src={`${process.env.BLOB_STORAGE_URL}/${website.websiteId}/${component.settings.imagePath}`}
          alt={component.settings.imageAltText}
        />
      )}
      <Container
        fluid
        {...component.attributes}
        style={layoutContainerStyles}
        className={`page-content-section ${component.settings.imagePath ? 'background-image-container' : ''}`}
      >
        {component.rows.map((row, index) => (
          <Row key={index} {...row.attributes}>
            {row.columns.map((col, index) => (
              <Col key={index} {...col.attributes}>
                {col.components.length === 0 && (col.hide === false || typeof col.hide === 'undefined') && (
                  <div className="empty-column">
                    <h4>Area {index + 1}</h4>
                    <p>Empty Layout</p>
                  </div>
                )}
                {col.components.map((item, i) => (
                  <ContentComponent key={i} data={item} />
                ))}
              </Col>
            ))}
          </Row>
        ))}
      </Container>
    </div>
  );
};

Layout.propTypes = {
  component: PropTypes.object.isRequired,
  website: PropTypes.object.isRequired,
};
