import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './payments-accepted.css';

export const PaymentsAccepted = ({ data }) => {
	return (
		<Fragment>
			<h3>Payments Accepted</h3>
			<div className="payments-accepted">
				<div>{data.content.visa && <div className={`icon visa`} />}</div>
				<div>{data.content.mastercard && <div className={`icon mastercard`} />}</div>
				<div>{data.content.maestro && <div className={`icon maestro`} />}</div>
				<div>{data.content.americanexpress && <div className={`icon americanexpress`} />}</div>
				<div>{data.content.paypal && <div className={`icon paypal`} />}</div>
				<div>{data.content.directdebit && <div className={`icon directdebit`} />}</div>
				<div>{data.content.bacs && <div className={`icon bacs`} />}</div>
				<div>{data.content.cheque && <div className={`icon cheque`} />}</div>
				<div>{data.content.cash && <div className={`icon cash`} />}</div>
			</div>
		</Fragment>
	);
};

PaymentsAccepted.propTypes = {
	data: PropTypes.object.isRequired
};
