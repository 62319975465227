import React from 'react';
import PropTypes from 'prop-types';
import * as componentIds from '../template-component-ids';
import { ImageOverlayBox } from './content/image-overlay-box';
import { Wysiwyg } from './content/wysiwyg';
import { Image } from './content/image';
import { Card } from './content/card';
import { ReviewCarousel } from './content/review-carousel';
import { Document } from './content/document';
import { Gallery } from './content/gallery';
import { TickList } from './content/tickList';
import { ContactDetails } from './content/contact-details';
import { OpeningHours } from './content/opening-hours';
import { PaymentsAccepted } from './content/payments-accepted';
import { ContactForm } from './content/contact-form';
import { Map } from './content/map';
import { PrivacyPolicy } from './content/privacy-policy';
import { ReviewList } from './content/review-list';
import { LinkButton } from './content/link-button';
import { CookiePolicy } from './content/cookie-policy';

export const ContentComponent = ({ data }) => {
	const emptyComponentStyle = {
		backgroundColor: '#fff',
		border: 'dotted 2px silver',
		color: 'gray',
		textAlign: 'center',
		padding: '20px',
		marginBottom: '15px'
	};

	return data.isModified || data.suppressNewStatus ? (
		<div className="mt-4 mb-4">
			{data.contentId === componentIds.IMAGE_OVERLAY_BOX && <ImageOverlayBox data={data} />}
			{data.contentId === componentIds.WYSIWYG && <Wysiwyg data={data} />}
			{data.contentId === componentIds.IMAGE && <Image data={data} />}
			{data.contentId === componentIds.CARD && <Card data={data} />}
			{data.contentId === componentIds.REVIEW_CAROUSEL && <ReviewCarousel />}
			{data.contentId === componentIds.DOCUMENT && <Document data={data} />}
			{data.contentId === componentIds.GALLERY && <Gallery data={data} />}
			{data.contentId === componentIds.TICK_LIST && <TickList data={data} />}
			{data.contentId === componentIds.CONTACT_DETAILS && <ContactDetails data={data} />}
			{data.contentId === componentIds.OPENING_HOURS && <OpeningHours data={data} />}
			{data.contentId === componentIds.PAYMENTS_ACCEPTED && <PaymentsAccepted data={data} />}
			{data.contentId === componentIds.CONTACT_FORM && <ContactForm data={data} />}
			{data.contentId === componentIds.MAP && <Map data={data} />}
			{data.contentId === componentIds.PRIVACY_POLICY && <PrivacyPolicy data={data} />}
			{data.contentId === componentIds.REVIEW_LIST && <ReviewList data={data} />}
			{data.contentId === componentIds.LINK_BUTTON && <LinkButton data={data} />}
			{data.contentId === componentIds.COOKIE_POLICY && <CookiePolicy data={data} />}
		</div>
	) : (
		<div style={emptyComponentStyle}>
			<b style={{ color: 'hotpink' }}>New</b> <span style={{ fontSize: '12px', fontWeight: 'bold' }}>{data.name}</span>
		</div>
	);
};

ContentComponent.propTypes = {
	data: PropTypes.object.isRequired
};
