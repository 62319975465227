import { useContext } from 'react';
import { TemplatesContext } from '../../template-context';
import { useMediaQuery } from 'react-responsive';

export function useTheme({ colorIndex, layoutPaddingTopOn = true, layoutPaddingBottomOn = true }) {
	const { website } = useContext(TemplatesContext);
	const primary = website.theme.colors[colorIndex].primary;
	const secondary = website.theme.colors[colorIndex].secondary;

	const isMobile = useMediaQuery({ maxWidth: 767 });
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1199 });

	const container = {
		backgroundColor: `rgba(${primary.red},${primary.green},${primary.blue},1)`,
		color: `rgb(${secondary.red},${secondary.green},${secondary.blue})`
	};

	const containerWithOpacity = {
		...container,
		backgroundColor: `rgba(${primary.red},${primary.green},${primary.blue},${website.content.globalStyles.opacity})`
	};

	//Layout Padding Top
	let layoutPaddingTop = '70px';
	layoutPaddingTop = isMobile ? '30px' : layoutPaddingTop;
	layoutPaddingTop = isTablet ? '50px' : layoutPaddingTop;
	layoutPaddingTop = layoutPaddingTopOn ? layoutPaddingTop : '0';

	//Layout Padding Botton
	let layoutPaddingBottom = '70px';
	layoutPaddingBottom = isMobile ? '30px' : layoutPaddingBottom;
	layoutPaddingBottom = isTablet ? '50px' : layoutPaddingBottom;
	layoutPaddingBottom = layoutPaddingBottomOn ? layoutPaddingBottom : '0';

	const layoutContainer = {
		...container,
		paddingTop: layoutPaddingTop,
		paddingBottom: layoutPaddingBottom
	};

	return {
		layoutContainerStyles: layoutContainer,
		containerStyles: container,
		containerStylesWithOpacity: containerWithOpacity,
		primaryColors: primary,
		secondaryColors: secondary
	};
}
