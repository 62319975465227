import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TemplatesContext } from '../../template-context';
import { useTheme } from '../../templates/common/themes';
import TemplateLink from '../../templates/common/template-link';
import './card.css';

export const Card = ({ data }) => {
	const { website } = useContext(TemplatesContext);
	const button = useTheme({ colorIndex: data.settings.linkButtonColorIndex > -1 ? data.settings.linkButtonColorIndex : 3 });
	const cardComponent = useTheme({ colorIndex: data.settings.themeColorIndex > -1 ? data.settings.themeColorIndex : 0 });

	return (
		<div className="card-component" style={cardComponent.containerStyles}>
			{data.content.imagePath && (
				<div>
					<img
						className="w-100"
						src={`${process.env.BLOB_STORAGE_URL}/${website.websiteId}/${data.content.imagePath}`}
						alt={data.settings.imageAltText}
					/>
				</div>
			)}
			<div className="info-section" style={{ textAlign: data.settings.textAlign }}>
				<div dangerouslySetInnerHTML={{ __html: data.content.userContent || '' }} />
				{data.content.linkTitle &&
				data.content.linkPath && (
					<div className="btn" style={button.containerStyles}>
						<TemplateLink linkPath={data.content.linkPath} linkName={data.content.linkTitle} />
					</div>
				)}
			</div>
		</div>
	);
};

Card.propTypes = {
	data: PropTypes.object.isRequired
};
