import React, { useState, useEffect, useCallback, useContext } from 'react';
import TemplateLink from '../../templates/common/template-link';
import './notice-bar.css';
import Cookies from 'universal-cookie';
import { useTheme } from '../../templates/common/themes';
import { TemplatesContext } from '../../template-context';

const NoticeBar = () => {
	const cookies = new Cookies();
	const [ cookiesAcknowledged, setCookiesAcknowledged ] = useState(true);
	const { website } = useContext(TemplatesContext);
	const cookieBarTheme = useTheme({
		colorIndex: website.content.siteComponents.gdpr.settings.themeColorIndex > -1 ? website.content.siteComponents.gdpr.settings.themeColorIndex : 3
	});
	const cookieButtonTheme = useTheme({
		colorIndex:
			website.content.siteComponents.gdpr.settings.themeButtonColorIndex > -1 ? website.content.siteComponents.gdpr.settings.themeButtonColorIndex : 4
	});

	const setCookieAcknowledgement = useCallback(
		() => {
			cookies.set('cookies-acknowledged', true, { path: '/', maxAge: 7890000 /* 3 months */ });
			setCookiesAcknowledged(true);
		},
		[ cookies ]
	);

	useEffect(
		() => {
			const userAcceptance = cookies.get('cookies-acknowledged');
			setCookiesAcknowledged(userAcceptance);
		},
		[ cookies, website ]
	);

	return cookiesAcknowledged ? null : (
		<div id="gdpr-notice-bar">
			<div className="inner-wrapper" style={cookieBarTheme.containerStyles}>
				<div>
					This website uses cookies to ensure you get the best experience on our website.{' '}
					<TemplateLink linkName="Cookie Policy" linkPath="/cookie-policy" />
				</div>
				<div className="got-it-button" onClick={setCookieAcknowledgement} style={cookieButtonTheme.containerStyles}>
					Got it!
				</div>
			</div>
		</div>
	);
};

export default NoticeBar;
