import React, { useContext, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { TemplatesContext } from '../../template-context';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './gallery.css';

export const Gallery = ({ data }) => {
	const { website } = useContext(TemplatesContext);
	const [ isLightboxOpen, setIsLightboxOpen ] = useState(false);
	const [ lightboxPhotoIndex, setLightboxPhotoIndex ] = useState(0);

	const handleImageClick = (name) => () => {
		const index = data.content.images.findIndex((item) => {
			return item.imageName === name;
		});

		setLightboxPhotoIndex(index);
		setIsLightboxOpen(true);
	};

	return (
		<Fragment>
			{isLightboxOpen && (
				<Lightbox
					imageCaption={data.content.images[lightboxPhotoIndex].caption}
					mainSrc={`${process.env.BLOB_STORAGE_URL}/${website.websiteId}/${data.content.images[lightboxPhotoIndex].imageName}`}
					nextSrc={`${process.env.BLOB_STORAGE_URL}/${website.websiteId}/${data.content.images[(lightboxPhotoIndex + 1) % data.content.images.length]
						.imageName}`}
					prevSrc={`${process.env.BLOB_STORAGE_URL}/${website.websiteId}/${data.content.images[
						(lightboxPhotoIndex + data.content.images.length - 1) % data.content.images.length
					].imageName}`}
					onCloseRequest={() => setIsLightboxOpen(false)}
					onMovePrevRequest={() => setLightboxPhotoIndex((lightboxPhotoIndex + data.content.images.length - 1) % data.content.images.length)}
					onMoveNextRequest={() => setLightboxPhotoIndex((lightboxPhotoIndex + data.content.images.length + 1) % data.content.images.length)}
				/>
			)}

			{data.content.images.length > 0 && (
				<Container fluid className="tiled-gallery p-0 pl-2 pr-2">
					<Row>
						<Col xs="12" xl="4" className="p-1">
							{data.content.images
								.filter((item, index) => {
									return index % 3 == 0;
								})
								.map((item, index) => (
									<div
										key={index}
										className={`gallery-title-image ${data.settings.displayType === 'equalized'
											? 'block-2'
											: index % 2 == 0 ? 'block-1' : 'block-3'} mb-2`}
									>
										<img
											className="w-100"
											src={`${process.env.BLOB_STORAGE_URL}/${website.websiteId}/${item.imageName}`}
											alt={item.imageAltText}
											onClick={handleImageClick(item.imageName)}
										/>
									</div>
								))}
						</Col>
						<Col xs="12" xl="4" className="p-1">
							{data.content.images
								.filter((item, index) => {
									return index % 3 == 1;
								})
								.map((item, index) => (
									<div key={index} className={`gallery-title-image ${index % 2 == 0 ? 'block-2' : 'block-2'} mb-2`}>
										<img
											className="w-100"
											src={`${process.env.BLOB_STORAGE_URL}/${website.websiteId}/${item.imageName}`}
											alt={item.imageAltText}
											onClick={handleImageClick(item.imageName)}
										/>
									</div>
								))}
						</Col>

						<Col xs="12" xl="4" className="p-1">
							{data.content.images
								.filter((item, index) => {
									return index % 3 == 2;
								})
								.map((item, index) => (
									<div
										key={index}
										className={`gallery-title-image ${data.settings.displayType === 'equalized'
											? 'block-2'
											: index % 2 == 0 ? 'block-3' : 'block-1'} mb-2`}
									>
										<img
											className="w-100"
											src={`${process.env.BLOB_STORAGE_URL}/${website.websiteId}/${item.imageName}`}
											alt={item.imageAltText}
											onClick={handleImageClick(item.imageName)}
										/>
									</div>
								))}
						</Col>
					</Row>
				</Container>
			)}
		</Fragment>
	);
};

Gallery.propTypes = {
	data: PropTypes.object.isRequired
};
