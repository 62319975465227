export const IMAGE_OVERLAY_BOX = 'c37895a6-c8f0-4441-b64f-aac7b145d66a';
export const WYSIWYG = '78991b24-6069-4b83-8170-c19312cd550e';
export const IMAGE = '3fc56566-1a61-4241-a164-a409497b95b2';
export const CARD = '16ad4724-a0b7-466b-b32f-68a2147f4c09';
export const REVIEW_CAROUSEL = 'cbe3965a-3296-4506-9853-4ccfab5d46b4';
export const DOCUMENT = 'dcf6b7c3-a39f-4955-9504-70d035beddc7';
export const GALLERY = '73296ceb-7a4c-4dca-bd18-a2e1aa3a518b';
export const TICK_LIST = 'e5c01445-fcbd-4014-96cf-ed9308103c32';
export const CONTACT_DETAILS = 'd555cc71-e2d8-437d-9822-439d24bb97d7';
export const OPENING_HOURS = '80f13a19-cbeb-4c7a-a57a-f316500f1db9';
export const PAYMENTS_ACCEPTED = '84a2bc25-30e3-4e63-8cff-ab36800beeb8';
export const CONTACT_FORM = '845fcd63-ec2f-419a-b415-fdd36c8b831c';
export const MAP = '6202a3ec-9c6e-46e9-85fc-4959a15bb2e5';
export const PRIVACY_POLICY = 'a0bc4721-b5c3-4281-99ea-acfafa9929b5';
export const REVIEW_LIST = 'ae3e1679-1e75-4541-8957-7f70828ed6f7';
export const LINK_BUTTON = 'd733b76a-8bed-4b3f-91c8-d12d8cd061c3';
export const COOKIE_POLICY = '7ad50e52-7b6c-4f07-a515-2328c62effc9';
