import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { TemplatesContext } from '../../template-context';

export const CookiePolicy = ({ data }) => {
	const { website } = useContext(TemplatesContext);
	const companyName = data.shared.companyName ? website.content.siteComponents.shared.companyName : data.content.companyName;

	return website ? (
		<Fragment>
			<h3>Cookie Policy</h3>
			<h4>What are cookies?</h4>
			<p>
				Like most websites, <b>{companyName}</b> uses an internet browser feature called cookies. A cookie is a simple text file which gets stored on
				your computer when you visit our site. We use cookies to help identify your computer so we can tailor your user experience.
			</p>
			<h4>How do we use them?</h4>
			<p>
				When you visit <b>{website.domainName}</b> for the first time a cookie will be stored on your computer. When you come back again we’re able to
				recognise that you’re a returning visitor. These cookies don’t tell us who you are, they just identify you as a user, tell us how users engage
				with our website and whether they have visited before.
			</p>
			<p>
				We use cookies to improve your experience when you are using our website. The cookies allow us to monitor how our customers and visitors use the
				site, and help us make it clearer and easier to use.
			</p>
			<h4>
				Should I be worried about <b>{companyName}&apos;s</b> use of cookies?
			</h4>
			<p>
				Although cookies can be used to track movements across and between websites, they do so without identifying any personal or confidential
				information about existing or potential <b>{companyName}</b> customers. Our use of cookies is purely based on enhancing our customers&apos;
				online experience.
			</p>
			<p>
				The cookies we use on our website are what’s known as ‘session cookies’. Some expire as soon as you leave the site, most expire within 30 days
				of your visit and certain cookies, those associated with google analytics for example, expire automatically after six months.
			</p>
			<h4>What if I don’t want cookies?</h4>
			<p>
				As a user of our site, you can turn off the cookies if you want to. You will need to go to your web browser and follow the instructions at the
				bottom of this page. We’ve included details for the most common browsers.
			</p>
			<h4>
				<b>{companyName}</b>’s Cookies Policy
			</h4>
			<p>
				By using this website, you agree to our use of cookies and third party cookies that are used by our sales partners or affiliates. In addition,
				any visitor that has clicked on a <b>{companyName}</b> advertisement on another website that links to <b>{website.domainName}</b> will be sent
				anonymous cookies that will allow us to analyse advertising effectiveness.
			</p>
			<p>If you decide not to accept our cookies, some features of our website may not work as well as usual or not function at all as a result.</p>
			<p>
				Our website may contain links to other websites which are outside our control and are not covered by this Policy. If you access other sites
				using the links provided, the operators of these sites may collect information from you which will be used by them in accordance with their
				privacy or cookie policy, which may differ from ours. We cannot be responsible for personal information that third parties may collect, store
				and use through their website. You should always read the policies of each website you visit carefully.
			</p>
			<h4>Where can I find out more about cookies?</h4>
			<p>
				There’s a wealth of information about what cookies are, how they’re used by websites, and what you can do to control their use available on the
				internet. We think a good place to start to get an overview of what cookies are is{' '}
				<a href="https://www.aboutcookies.org" target="_blank" rel="noopener noreferrer">
					www.aboutcookies.org
				</a>.
			</p>
			<p>
				If you’d like to learn more about the regulations that govern the use of cookies in the UK, just take a look at the information provided by the
				Information Commissioner’s Office here:{' '}
				<a href="https://ico.org.uk/for-organisations/guide-to-pecr/cookies-and-similar-technologies/" target="_blank" rel="noopener noreferrer">
					www.ico.gov.uk
				</a>
			</p>
			<h4>Take control of your cookies</h4>
			<p>
				You don’t have to accept cookies on any site if you don’t want to and pretty much every browser gives you an option to turn them off completely
				or to only accept certain types. It’s important to remember, however, that if you limit the cookies you accept or turn them off completely you
				may find that websites don’t work as well or as quickly when you visit them.
			</p>
			<p>To adjust the cookie options on your browser, take a look at the instructions for most types of browser below.</p>
			<h4>Internet Explorer 8 and later versions</h4>
			<p>
				Go to the Tools menu and choose Internet Options Click on the Privacy tab and you’ll see a slider that allows you to change your settings. The
				slider allows a range of settings, from Block all Cookies to Accept all Cookies. Simply choose the level of protection you desire and click
				‘OK’. There are more advanced settings that you can access by clicking advanced in the dialogue box. If you choose this option you can modify
				your settings more precisely. For full details about the options available to you, take a look{' '}
				<a
					href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-8"
					target="_blank"
					rel="noopener noreferrer"
				>
					here
				</a>
			</p>
			<h4>Google Chrome</h4>
			<p>
				Go to the &apos;spanner icon&apos; on the toolbar. Click on settings and select &apos;under the hood&apos;. Choose the content settings in the
				privacy section and click the Cookies tab in the box that appears. Choose the level of protection you require and close the dialogue box. For
				full details about the options available to you, take a look{' '}
				<a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank" rel="noopener noreferrer">
					here
				</a>.
			</p>
			<h4>Firefox 3+ for Windows</h4>
			<p>
				Go to the Tools menu and select Options (If you’re using Linux you’ll need to go to the Edit menu and select Preferences). Click on the Privacy
				tab and select Cookies (If that option isn’t available, select ‘Use custom settings for history’ from the drop down menu) and choose the level
				of protection you require.
			</p>
			<h4>Firefox 3+ for Mac</h4>
			<p>
				Go to the Firefox or Edit menu and choose Preferences. Click on the Privacy tab, select Cookies (If that option isn’t available, select ‘Use
				custom settings for history’ from the drop down menu) and choose the level of protection you require. For full details about the options
				available to you, take a look{' '}
				<a
					href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectlocale=en-US&redirectslug=enable-and-disable-cookies-website-preferences"
					target="_blank"
					rel="noopener noreferrer"
				>
					here
				</a>.
			</p>
			<h4>Safari 4+</h4>
			<p>
				Got to the Safari menu and choose Preferences. Select the Privacy tab and choose your required settings. For full details about the options
				available to you, take a look{' '}
				<a href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">
					here
				</a>.
			</p>
		</Fragment>
	) : null;
};

CookiePolicy.propTypes = {
	data: PropTypes.object.isRequired
};
