import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '../../templates/common/themes';

export const LinkButton = ({ data }) => {
	const buttonTheme = useTheme({ colorIndex: data.settings.linkButtonColorIndex > -1 ? data.settings.linkButtonColorIndex : 0 });

	const buttonWrapperStyle = {
		display: 'flex',
		justifyContent: data.settings.justifyContent ? data.settings.justifyContent : 'flex-start'
	};

	const buttonStyle = {
		...buttonTheme.containerStyles,
		textDecoration: 'none'
	};

	return data.content.text && data.content.url ? (
		<div style={buttonWrapperStyle}>
			<a href={data.content.url} className="btn" style={buttonStyle} target="_blank" rel="noopener noreferrer">
				{data.content.text}
			</a>
		</div>
	) : null;
};

LinkButton.propTypes = {
	data: PropTypes.object.isRequired
};
