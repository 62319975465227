import React, { useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useTheme } from '../../templates/common/themes';
import { TemplatesContext } from '../../template-context';
import { useContent } from './useContent';
import { useStyle } from './useStyle';
import './header2.css';

export const Header2 = () => {
	const { website } = useContext(TemplatesContext);
	const header = useContent();
	const styles = useStyle();
	const { containerStyles } = useTheme({ colorIndex: styles.themeColorIndex });

	return (
		website && (
			<header>
				<Container fluid style={containerStyles}>
					<Row className="main-section">
						<Col xs="12">
							<h3>New header coming soon</h3>
							<p>{header.companyName}</p>
						</Col>
					</Row>
				</Container>
			</header>
		)
	);
};
