import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import './payments-accepted.css';
import { TemplatesContext } from '../../template-context';

export const PrivacyPolicy = ({ data }) => {
	const { website } = useContext(TemplatesContext);
	const companyName = data.shared.companyName ? website.content.siteComponents.shared.companyName : data.content.companyName;
	const email = data.shared.email ? website.content.siteComponents.shared.email : data.content.email;
	const address = data.shared.address ? website.content.siteComponents.shared.address : data.content.address;

	return (
		<Fragment>
			<h3>Privacy Policy</h3>
			<p>
				This Privacy Policy applies to <b>{companyName}</b>. (along with all subsidiaries, affiliates, successors and assigns thereof, referred to
				hereinafter collectively as &apos;<b>{companyName}</b>&apos;, &apos;we&apos;, &apos;our&apos; and/or &apos;us&apos;), our websites and services.{' '}
				<b>{companyName}</b> knows that you care how information about you is used and shared, and we appreciate your expectation that we will do so
				carefully and sensibly.{' '}
			</p>
			<p>
				By visiting this or another <b>{companyName}</b> website displaying this Privacy Policy or a link thereto (a &apos;Website&apos;, or
				collectively the &apos;Websites&apos;), or by otherwise interacting with <b>{companyName}</b> you agree to accept the practices described in
				this Privacy Policy. This Privacy Policy is further subject to the <b>{companyName}</b> Services Agreement related to the <b>{companyName}</b>{' '}
				services you utilise and/or purchase. For questions on this Privacy Policy please contact our team via the contact information found on this
				Website.
			</p>
			<p>
				<b>{companyName}</b> values your privacy and is dedicated to protecting your personal information and providing you with notice about what
				personally identifiable information we collect and how it is used, what choices you have regarding the collection, use and distribution of that
				information, the security procedures we implement to protect that information and what access you may have to correct any inaccuracies in the
				information we have collected from you.{' '}
			</p>
			<h4>What Information Is Collected</h4>
			<p>
				We receive and store any information you enter on our Websites or give us in any other way, such as via telephone, electronic mail, online or
				otherwise. For example, this information may be collected when you contact us for customer support.{' '}
			</p>
			<p>Here are the types of information we gather: </p>
			<p>
				<b>Contact Information:</b> We collect and retain personal contact information that you submit to us voluntarily and this includes, but is not
				limited to, your name, mailing address, web address, telephone number, and e-mail address. This information may include your full name, mailing
				address, phone number, email address, and, where provided, your fax number. Such contact information may be used, for example, to communicate
				with you when necessary with respect to transactions. Additionally, we may collect contact information provided to us during any correspondence
				relating to customer support, as well as in connection with products and services offered on our Websites.
			</p>
			<p>
				<b>Financial Information:</b> We collect and retain certain financial information from when you purchase our products and services. For example,
				when you sign up for any other product or service through <b>{companyName}</b>, we require you to provide a credit card number and billing
				address before we will process that application. We will not sell, share or rent any financial information collected from you except as
				disclosed in this Privacy Policy or as necessary to fulfil any order for our products or services.{' '}
			</p>
			<p>
				<b>Demographic Information:</b> We may collect and store anonymous demographic information (such as your postcode, gender and age), and use this
				data to tailor your experience on our Websites, provide content that we think you might be interested in, perform general improvements to the
				Websites, and display the content according to your preferences. Such demographic information may be shared with partners, market researchers
				and other third parties on an aggregate, non-personally identifiable basis. No personally identifiable information will be linked to such
				aggregated demographic information that is shared with third parties.
			</p>
			<p>
				<b>Other Information:</b> We collect and store certain types of information whenever you interact with us. For example, our servers track the
				type of web browser you are using and the page from which you link. Additionally, we log IP addresses (a unique number assigned to every
				computer on the Internet) in order to improve security, analyse trends and administer the Websites. We may track your movement within the
				Websites, the third-party website pages from which you were referred, access times and browser types. We use this information to gauge the
				effectiveness of our Websites, track information for statistical purposes and to improve the products and services we provide. We collect this
				information to better develop a sense of what services our customers need and identify areas of improvement to the Websites. We may use such
				information for marketing purposes, and to send information to you that we believe might be of interest. In addition, such information may be
				shared with partners, market researchers and other third parties on an aggregate, non-personally identifiable basis. No personally identifiable
				information will be linked to such aggregated information shared with third parties.
			</p>
			<p>
				We may supplement the information you provide us by acquiring publicly or commercially available information as available in the public domain
				or from third parties, for instance, to verify your address or to update your information and to create a profile of your preferences. We tie
				your personally identifiable information to information in the profile, in order to improve the content of the Websites for you or to inform you
				about products or services that we think will be of interest to you. From time to time, we may also ask you to participate in surveys or other
				communications conducted by us or third parties designed to help us improve the Websites or the products or services we provide.{' '}
			</p>
			<p>
				<b>Cookies:</b> The Websites use &apos;cookies&apos; such purposes as enhancing your online experience by making it easier for you to navigate
				through the Websites and making certain features work better. Cookies are text files which are commonly deposited by websites on a user&apos;s
				hard drive when the user visits a website. The file identifies a user&apos;s computer and can record the user&apos;s preferences and other data
				about the user&apos;s website visit. We do not extract information about individual users during this process. Your web browser may enable you
				to disable cookies, but please note that certain services may not function correctly without it and your experience on our Websites may be
				hindered.{' '}
			</p>
			<p>
				<b>Third Party Cookies:</b> We may use third-party advertising companies to serve ads on our behalf across the Internet. These companies may
				collect and use information about your visits to this and other websites and your interaction with our products and services in order to provide
				advertisements about goods and services of interest to you. However, no personally identifiable information (such as your name, address, email
				address or telephone number) will be connected with such information. They may use information about your visits to this and other websites to
				target advertisements for goods and services and may be used to keep track of user response to each advertisement. These targeted advertisements
				may appear on our Websites or on other sites that you visit. The anonymous information is collected through the use of a pixel tag or cookies,
				which are industry standard technologies used by most major websites. If you do not want such companies to collect this information you may
				opt-out.{' '}
			</p>
			<p>
				To learn more about the use of this information or choose not to have this information used by certain third-party advertising partners, please
				visit the Network Advertising Initiative at <a href="http://www.networkadvertising.org/choices">http://www.networkadvertising.org/choices</a>.
				Please note that if you delete your cookies, use a different browser, or buy a new computer, you will need to renew your opt-out choice.{' '}
			</p>
			<p>
				<b>Web Beacons:</b> The Websites may contain electronic images (called a &apos;single-pixel GIF&apos; or a &apos;web beacon&apos;) that allow a
				website to track the effectiveness of marketing campaigns. No personally identifiable information will be transmitted via web beacons.{' '}
			</p>
			<p>
				<b>Telephone Recording and Monitoring:</b> To ensure <b>{companyName}</b> customers receive quality service, <b>{companyName}</b> selects phone
				calls for recording and/or monitoring. These calls, between <b>{companyName}</b> customers (or potential customers) and employees, are evaluated
				by <b>{companyName}</b> representatives. This is to guarantee that prompt, consistent assistance and accurate information is delivered in a
				professional manner. In contacting <b>{companyName}</b> creating an account with <b>{companyName}</b>, or by otherwise utilising any{' '}
				<b>{companyName}</b> products or services, you hereby consent to any such call recording and/or monitoring.
			</p>
			<h4>How Personal Information Is Used</h4>
			<p>
				<b>Our Use:</b> We receive and store any personal information you enter on our Websites or give to us voluntarily in any other way and may use
				it to contact you from time to time. We take steps designed to ensure that only the employees who need access to your personal information to
				fulfil their employment duties will have access to it. By registering on a Website and providing your telephone number, you agree that this
				action constitutes a purchase, enquiry and/or application for purposes of telemarketing laws. Regardless of the fact that your telephone and/or
				cell number may be listed with the TPS, you are providing your express written consent to receive future information (including telemarketing)
				about products and services from us and/or our affiliates, and you hereby agree and consent to our contacting you using the information you have
				provided and will provide to us. This means we may contact you by e-mail, phone and/or cell number (including use of automated dialling
				equipment and/or pre-recorded calls), text (SMS) message, social networks or any other means of communication that your wireless or other
				telecommunications device may be capable of receiving (i.e. video, etc.). We may also send you information or offers from time to time to the
				postal address or email address we have on file (as further outlined in this Privacy Policy). You further acknowledge that you are not required
				to agree directly or indirectly or enter into an agreement regarding our telemarketing efforts as a condition of purchasing any goods or
				services from us or our affiliates.{' '}
			</p>
			<p>
				<b>Electronic Mail:</b> From time to time, <b>{companyName}</b> may use your personal information to contact you by electronic mail concerning
				our services. These communications are often intended to inform you of important information regarding your account, or about general services
				provided by <b>{companyName}</b> and/or its affiliates. If you do not want to receive email from us or our affiliates regarding new services,
				please click the &apos;unsubscribe&apos; link which is included at the bottom of any email you receive from us. However, in order to fulfil our
				service obligations to you, we will continue to send you emails regarding your account administration, as well as any necessary information,
				such as renewal notices and instructions. By providing us with your email address you consent to receiving communications from us electronically
				and all notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such
				communications be in writing.{' '}
			</p>
			<p>
				<b>Service Related Uses:</b> We may use your personal information to contact you about the goods or services you have purchased from us, or for
				other purposes in which you have expressed an interest, for example, if you have contacted customer service or made an enquiry. We will also
				provide you with service and account related announcements. For instance, if the delivery of a service or product is delayed we might send you
				an email, or other message, or if there is some service issue with something you might have ordered. Generally, you may not opt-out of these
				communications, which are not promotional in nature. If you do not wish to receive them, you have the option to cancel your services with us.{' '}
			</p>
			<p>
				<b>Protection of {companyName} and Others:</b> We may release account and other personal information when we are required to do so by law, court
				order, subpoena or other government or law enforcement authority or regulatory agency, including ICANN, in order to enforce or apply our
				Services Agreement or other agreements, or when we believe in good faith that disclosing this information is necessary or advisable, including
				for example, to protect the rights, property, or safety of <b>{companyName}</b>, our users, or others. This includes exchanging information with
				other companies and organisations for fraud protection, credit risk reduction or other similar purposes. Sites Other Than <b>{companyName}</b>,{' '}
				<b>{companyName}</b> has no control over and is not responsible for the privacy policies or information gathering practices of other websites,
				including those to which we may link and those which may link to us. We strongly recommend that you review the privacy policies of every online
				service you use before submitting any personal information and direct any concerns to the site administrator of that website.{' '}
			</p>
			<h4>How Long We Keep Your Personal Data</h4>
			<p>
				We keep information only for as long as we need it to provide you services, manage our business or as required by law or contract. Where you
				have a contract with us, we will retain your data for the length of the contract, and will further retain that information for a time period
				consistent with our legal or regulatory responsibilities, after you terminate all your services with us to resolve disputes, enforce our
				relevant Service Agreement, and to adhere to the technical and legal requirements and constraints related to the security, integrity and
				operation of the Sites. If you have any questions about how long we will keep your specific data, please contact us.
			</p>
			<h4>How We Keep Your Personal Data Safe</h4>
			<p>
				We work to protect the security of your financial information during transmission by using Secure Sockets Layer (&apos;SSL&apos;) software,
				which encrypts the information you input on the Site. However, no method of transmission over the Internet or method of electronic storage is
				completely secure and we cannot guarantee its absolute security. You can further help to ensure your security by taking a few simple
				precautions. For instance, it is important to protect against unauthorised access to your computer, and to be sure to sign off when you are
				finished using a shared computer.
			</p>
			<h4>Limitation Of Liability</h4>
			<p>
				<b>{companyName}</b> assumes no responsibility or liability with regard to any theft, loss, alteration or misuse of personal or other
				information lawfully provided by <b>{companyName}</b> to third parties, or with regard to the failure of any third party to abide by this
				privacy policy or such third party.
			</p>
			<h4>Contacting Us</h4>
			<p>
				If you have questions or concerns regarding this Privacy Policy, you can contact us by writing to us at{' '}
				<b>
					<a href={`mailto: ${email}`}>{email}</a>
				</b>{' '}
				or by mail at <b>{address && address.replace(/\n/g, ', ')}</b>{' '}
			</p>
		</Fragment>
	);
};

PrivacyPolicy.propTypes = {
	data: PropTypes.object.isRequired
};
