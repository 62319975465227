import React, { useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useTheme } from '../../templates/common/themes';
import { TemplatesContext } from '../../template-context';
import { useContent } from './useContent';
import { useStyle } from './useStyle';
import './footer2.css';
import { PowerBar } from './power-bar';

export const Footer2 = () => {
	const { website } = useContext(TemplatesContext);
	const styles = useStyle();
	const { containerStyles } = useTheme({ colorIndex: styles.footerColorIndex });
	const footer = useContent();

	return (
		website && (
			<footer>
				<Container fluid className="page-content-section pt-4 pb-4" style={containerStyles}>
					<Row className="main-section">
						<Col xs="12">
							<h3>New Footer coming soon</h3>
							<p>{footer.companyName}</p>
						</Col>
					</Row>
				</Container>
				<PowerBar />
			</footer>
		)
	);
};
