import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TemplatesContext } from '../../template-context';
import * as Mode from '../../template-modes';
//import { Link as GatsbyLink } from 'gatsby'; //Links behave like an SPA but causes builds of admin and template apps to break - Trello card created
import GatsbyLink from 'gatsby-link'; //works but there is now a page refresh
import { usePage } from './usePage';

const AdminLink = ({ linkPath, linkName, className }) => {
	const page = usePage(linkPath);

	const handleLinkClick = (e) => {
		e.preventDefault();

		window.top.postMessage({ templatePage: page }, `${location.protocol}//${location.hostname}${location.port && ':' + location.port}`);
		//Return iframe scroll to top when template page changes
		window.scrollTo(0, 0);
	};

	return linkPath.startsWith('#') ? (
		/* Same page anchor */
		<a href={linkPath} className={className}>
			{linkName}
		</a>
	) : (
		<a href="" onClick={handleLinkClick} className={className}>
			{linkName}
		</a>
	);
};

AdminLink.propTypes = {
	linkPath: PropTypes.string,
	linkName: PropTypes.string,
	className: PropTypes.string
};

const TemplateLink = ({ linkPath, linkName, className }) => {
	const { mode } = useContext(TemplatesContext);

	switch (mode) {
		case Mode.ADMIN:
			return <AdminLink linkName={linkName} linkPath={linkPath} className={className} />;

		case Mode.BUILD:
			return linkPath.startsWith('#') ? (
				/* Same page anchor */
				<a href={linkPath} className={className}>
					{linkName}
				</a>
			) : (
				<GatsbyLink to={linkPath} className={className}>
					{linkName}
				</GatsbyLink>
			);
		default:
			return null;
	}
};

TemplateLink.propTypes = {
	linkPath: PropTypes.string.isRequired,
	linkName: PropTypes.string.isRequired,
	className: PropTypes.string
};

export default TemplateLink;
