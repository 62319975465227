import React from 'react';
import { DefaultFooter } from './defaultFooter';
import PropTypes from 'prop-types';
import { Footer2 } from './footer2';

export const Footer = ({ componentId }) => {
	switch (componentId) {
		case 'bb19677f-6dff-4c8c-ae40-9bb7400dc68a':
			return <Footer2 />;
		default:
			return <DefaultFooter />;
	}
};

Footer.propTypes = {
	componentId: PropTypes.string
};
