import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './fonts.css';
import './index.css';
import * as Mode from './template-modes';
import TemplateProvider from './template-context';
import { LayoutComponents } from './components/layout-components';

const getPage = (website, websitePageId) => {
	let page = website.content.pageTemplates.find((item) => item.type === 'page' && item.websitePageId === websitePageId);

	if (!page) {
		const groupItems = [ ...website.content.pageTemplates.filter((item) => item.type === 'group') ];
		let pages = [];
		groupItems.forEach((element) => {
			pages = [ ...pages, ...element.pages ];
		});
		page = pages.find((item) => item.type === 'page' && item.websitePageId === websitePageId);
	}

	return page;
};

const Template = ({ mode, website, selectedWebsitePageId, buildPage }) => {
	//Fix - html omitted from static pages
	//Seems Gatsby requires page data upfront for static html to be populated and not rely on an effect to populate
	//Sites would still work as dom updated by gatsby scripts however no template html would appear in the view source of the site
	//which the business requires for SEO purposes
	const [ page, setPage ] = useState(buildPage ? buildPage : '');

	useEffect(
		() => {
			const page = getPage(website, selectedWebsitePageId);
			setPage(page);
		},
		[ website, selectedWebsitePageId ]
	);

	//Update website fonts
	useEffect(
		() => {
			const element = document.documentElement;

			if (website.fonts) {
				element.style.setProperty(`--tl-web-builder-font-family-primary`, website.fonts.primaryName);
				element.style.setProperty(`--tl-web-builder-font-family-secondary`, website.fonts.secondaryName);
				element.style.setProperty(`--tl-web-builder-font-weight-primary`, website.fonts.primaryWeight);
				element.style.setProperty(`--tl-web-builder-font-weight-secondary`, website.fonts.secondaryWeight);
			}
		},
		[ website.fonts ]
	);

	return (
		page && (
			<TemplateProvider mode={mode} page={page} website={website}>
				<div id="tl-web-builder-templates" data-icons-dark={website.theme.darkIcons}>
					<LayoutComponents />
				</div>
			</TemplateProvider>
		)
	);
};

Template.propTypes = {
	mode: PropTypes.number.isRequired,
	website: PropTypes.object.isRequired,
	selectedWebsitePageId: PropTypes.string.isRequired,
	buildPage: PropTypes.object
};

export { Template, Mode, getPage };
