import React, { useState, useEffect, useContext } from 'react';
import Swiper from 'react-id-swiper';
import dayjs from 'dayjs';
import StarRatings from 'react-star-ratings';
import 'swiper/css/swiper.css';
import './review-carousel.css';
import { TemplatesContext } from '../../template-context';

export const ReviewCarousel = () => {
	const reviewParams = {
		pagination: {
			el: '.swiper-pagination',
			clickable: true
		}
	};

	const { website } = useContext(TemplatesContext);
	const [ reviewState, setReviewState ] = useState([]);

	useEffect(
		() => {
			if (website.content.reviews) {
				const sortedReviews = [ ...website.content.reviews ].sort((a, b) => new Date(b.date) - new Date(a.date)).slice(0, 5);
				setReviewState(sortedReviews);
			}
		},
		[ website ]
	);

	return (
		reviewState.length > 0 && (
			<div className="reviews-component">
				<h4>What our customers are saying about us</h4>
				<Swiper {...reviewParams} rebuildOnUpdate={true}>
					{reviewState.map((item, index) => (
						<div key={index}>
							<StarRatings rating={item.starRating} starDimension="35px" starSpacing="5px" starRatedColor="#f7941e" />
							<p className="mt-4">{item.text}</p>
							<div className="font-weight-bold">
								<span>{item.name}</span>
								{item.location && (
									<span>
										{' '}
										in {item.location} {dayjs(item.date).format('D MMMM YYYY')}{' '}
									</span>
								)}
							</div>
						</div>
					))}
				</Swiper>
			</div>
		)
	);
};
