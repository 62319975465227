import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import StarRatings from 'react-star-ratings';
import './review-list.css';
import { TemplatesContext } from '../../template-context';
import { useTheme } from '../../templates/common/themes';

export const ReviewList = ({ data }) => {
	const { website } = useContext(TemplatesContext);
	const [ reviewState, setReviewState ] = useState([]);

	//Themes
	const reviewTheme = useTheme({ colorIndex: data.settings.reviewThemeColorIndex > -1 ? data.settings.reviewThemeColorIndex : 3 });

	useEffect(
		() => {
			if (website.content.reviews) {
				const sortedReviews = [ ...website.content.reviews ].sort((a, b) => new Date(b.date) - new Date(a.date));
				setReviewState(sortedReviews);
			}
		},
		[ website ]
	);

	return (
		reviewState.length > 0 && (
			<ul className="reviews-list-component">
				{reviewState.map((item, idx) => (
					<li key={idx} style={reviewTheme.containerStyles}>
						<div className="head-info">
							<div>
								{item.name && (
									<div className="user">
										{item.name} {item.location && ' in ' + item.location}
									</div>
								)}
								{item.date && <div className="date">{dayjs(item.date).format('D MMMM YYYY')}</div>}
							</div>
							<div>
								<StarRatings rating={item.starRating} starDimension="35px" starSpacing="5px" starRatedColor="#f7941e" />
							</div>
						</div>
						<hr />
						<div className="review-info">
							{item.title && <h4>{item.title}</h4>}
							<p>{item.text}</p>
						</div>
					</li>
				))}
			</ul>
		)
	);
};

ReviewList.propTypes = {
	data: PropTypes.object.isRequired
};
