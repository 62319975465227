import { useContext } from 'react';
import { TemplatesContext } from '../../template-context';

export function usePage(path) {
	const { website } = useContext(TemplatesContext);

	path = path ? path : '';
	let page = website.content.pageTemplates.find((item) => item.type === 'page' && item.path.toLowerCase() === path);

	if (!page) {
		const groupItems = [ ...website.content.pageTemplates.filter((item) => item.type === 'group') ];
		let pages = [];
		groupItems.forEach((element) => {
			pages = [ ...pages, ...element.pages ];
		});
		page = pages.find((item) => item.type === 'page' && item.path.toLowerCase() === path);
	}

	return page;
}
