import { useContext } from 'react';
import { TemplatesContext } from '../../template-context';

export const useContent = () => {
	const { website } = useContext(TemplatesContext);
	const sharedStatus = website.content.siteComponents.footer.shared;
	const footerContent = website.content.siteComponents.footer.content;
	const sharedContent = website.content.siteComponents.shared;

	return {
		companyName: typeof sharedStatus.companyName !== 'undefined' && !sharedStatus.companyName ? footerContent.companyName : sharedContent.companyName,
		phone: typeof sharedStatus.phone !== 'undefined' && !sharedStatus.phone ? footerContent.phone : sharedContent.phone,
		email: typeof sharedStatus.email !== 'undefined' && !sharedStatus.email ? footerContent.email : sharedContent.email,
		address: typeof sharedStatus.address !== 'undefined' && !sharedStatus.address ? footerContent.address : sharedContent.address,
		socialLinkInstagram:
			typeof sharedStatus.socialLinks !== 'undefined' && !sharedStatus.socialLinks
				? footerContent.socialLinkInstagram
				: sharedContent.socialLinkInstagram,
		socialLinkFacebook:
			typeof sharedStatus.socialLinks !== 'undefined' && !sharedStatus.socialLinks ? footerContent.socialLinkFacebook : sharedContent.socialLinkFacebook,
		socialLinkLinkedIn:
			typeof sharedStatus.socialLinks !== 'undefined' && !sharedStatus.socialLinks ? footerContent.socialLinkLinkedIn : sharedContent.socialLinkLinkedIn,
		socialLinkTwitter:
			typeof sharedStatus.socialLinks !== 'undefined' && !sharedStatus.socialLinks ? footerContent.socialLinkTwitter : sharedContent.socialLinkTwitter,
		socialLinkPinterest:
			typeof sharedStatus.socialLinks !== 'undefined' && !sharedStatus.socialLinks
				? footerContent.socialLinkPinterest
				: sharedContent.socialLinkPinterest,
		socialLinkYoutube:
			typeof sharedStatus.socialLinks !== 'undefined' && !sharedStatus.socialLinks ? footerContent.socialLinkYoutube : sharedContent.socialLinkYoutube
	};
};
