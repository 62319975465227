import React from 'react';
import PropTypes from 'prop-types';
import './opening-hours.css';

export const OpeningHours = ({ data }) => {
	return (
		<div className="openingTimes">
			<h3>Opening Times</h3>
			<Day name="Monday" times={data.content.mondayHours} />
			<Day name="Tuesday" times={data.content.tuesdayHours} />
			<Day name="Wednesday" times={data.content.wednesdayHours} />
			<Day name="Thursday" times={data.content.thursdayHours} />
			<Day name="Friday" times={data.content.fridayHours} />
			<Day name="Saturday" times={data.content.saturdayHours} />
			<Day name="Sunday" times={data.content.sundayHours} />
		</div>
	);
};

OpeningHours.propTypes = {
	data: PropTypes.object.isRequired
};

const Day = ({ name, times }) => {
	return (
		<div className="open-hours-day">
			<div className="day">
				<b>{name}</b>
			</div>
			<div className="time">{times}</div>
		</div>
	);
};

Day.propTypes = {
	name: PropTypes.string,
	times: PropTypes.string
};
