import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Template, Mode, getPage } from '../../tl-web-builder-templates/src/';
import { Helmet } from 'react-helmet';

const WebPage = ({ pageContext }) => {
	const buildPage = getPage(pageContext.website, pageContext.page.websitePageId);

	return (
		<Fragment>
			<Helmet>
				<title>{pageContext.page.metaPageTitle ? pageContext.page.metaPageTitle : pageContext.page.pageName}</title>
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta charset="UTF-8" />
				<meta httpEquiv="language" content="english" />
				<meta name="robots" content="index,follow" />
				<meta name="description" content={pageContext.page.metaPageDescription} />

				{pageContext.website.content.siteHtml &&
				pageContext.website.content.siteHtml.googleSiteVerification && (
					<meta name="google-site-verification" content={pageContext.website.content.siteHtml.googleSiteVerification} />
				)}

				<link
					rel="stylesheet"
					href="https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
					integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
					crossOrigin="anonymous"
				/>
				<link
					href="https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&family=Quicksand:wght@300;400;700&family=Roboto+Slab:wght@300;400;700&family=Roboto:wght@300;400;700&display=swap"
					rel="stylesheet"
				/>

				{/* Global site tag (gtag.js) - Google Analytics  */}
				{pageContext.website.content.siteHtml &&
				pageContext.website.content.siteHtml.googleAnalyticsMeasurementId && (
					<script async src={`https://www.googletagmanager.com/gtag/js?id=${pageContext.website.content.siteHtml.googleAnalyticsMeasurementId}`} />
				)}
				{pageContext.website.content.siteHtml &&
				pageContext.website.content.siteHtml.googleAnalyticsMeasurementId && (
					<script>
						{`window.dataLayer = window.dataLayer || [];
			  					function gtag(){dataLayer.push(arguments)}
			  					gtag('js', new Date());

			  					gtag('config', '${pageContext.website.content.siteHtml.googleAnalyticsMeasurementId}');`}
					</script>
				)}
			</Helmet>

			<Template mode={Mode.BUILD} website={pageContext.website} selectedWebsitePageId={pageContext.page.websitePageId} buildPage={buildPage} />
		</Fragment>
	);
};

WebPage.propTypes = {
	pageContext: PropTypes.object.isRequired,
	website: PropTypes.object.isRequired,
	page: PropTypes.object.isRequired
};

export default WebPage;
