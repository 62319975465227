import { useContext } from 'react';
import { TemplatesContext } from '../../template-context';

export const useContent = () => {
	const { website } = useContext(TemplatesContext);
	const sharedStatus = website.content.siteComponents.header.shared;
	const headerContent = website.content.siteComponents.header.content;
	const sharedContent = website.content.siteComponents.shared;

	return {
		...headerContent,
		imagePath: headerContent.imagePath, //Not shared
		imageAltText: headerContent.imageAltText, //Not shared
		companyName: typeof sharedStatus.companyName !== 'undefined' && !sharedStatus.companyName ? headerContent.companyName : sharedContent.companyName,
		phone: typeof sharedStatus.phone !== 'undefined' && !sharedStatus.phone ? headerContent.phone : sharedContent.phone,
		email: typeof sharedStatus.email !== 'undefined' && !sharedStatus.email ? headerContent.email : sharedContent.email,
		socialLinkInstagram:
			typeof sharedStatus.socialLinks !== 'undefined' && !sharedStatus.socialLinks
				? headerContent.socialLinkInstagram
				: sharedContent.socialLinkInstagram,
		socialLinkFacebook:
			typeof sharedStatus.socialLinks !== 'undefined' && !sharedStatus.socialLinks ? headerContent.socialLinkFacebook : sharedContent.socialLinkFacebook,
		socialLinkLinkedIn:
			typeof sharedStatus.socialLinks !== 'undefined' && !sharedStatus.socialLinks ? headerContent.socialLinkLinkedIn : sharedContent.socialLinkLinkedIn,
		socialLinkTwitter:
			typeof sharedStatus.socialLinks !== 'undefined' && !sharedStatus.socialLinks ? headerContent.socialLinkTwitter : sharedContent.socialLinkTwitter,
		socialLinkPinterest:
			typeof sharedStatus.socialLinks !== 'undefined' && !sharedStatus.socialLinks
				? headerContent.socialLinkPinterest
				: sharedContent.socialLinkPinterest,
		socialLinkYoutube:
			typeof sharedStatus.socialLinks !== 'undefined' && !sharedStatus.socialLinks ? headerContent.socialLinkYoutube : sharedContent.socialLinkYoutube
	};
};
