import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { useTheme } from '../../templates/common/themes';
import TemplateLink from '../../templates/common/template-link';

export const ImageOverlayBox = ({ data }) => {
	const { containerStylesWithOpacity } = useTheme({ colorIndex: data.settings.themeColorIndex > -1 ? data.settings.themeColorIndex : 0 });
	const { containerStyles } = useTheme({ colorIndex: data.settings.linkButtonColorIndex > -1 ? data.settings.linkButtonColorIndex : 3 });

	return (
		<Container fluid className="image-overlay-box-component p-0">
			<Row>
				<Col xs="12" md="9" className="content" style={{ ...containerStylesWithOpacity, padding: '40px' }}>
					<h1>{data.content.title}</h1>
					<div dangerouslySetInnerHTML={{ __html: data.content.userContent }} />
					<br />
					{data.content.linkTitle &&
					data.content.linkPath && (
						<div className="btn" style={containerStyles}>
							<TemplateLink linkPath={data.content.linkPath} linkName={data.content.linkTitle} />
						</div>
					)}
				</Col>
			</Row>
		</Container>
	);
};

ImageOverlayBox.defaultProps = {
	themeColorIndex: 1
};

ImageOverlayBox.propTypes = {
	data: PropTypes.object.isRequired
};
