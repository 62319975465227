import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Button } from 'reactstrap';
import { useTheme } from '../../templates/common/themes';
import './contact-form.css';

export const ContactForm = ({ data }) => {
	const [ messageName, setMessageName ] = useState('');
	const [ messagePhone, setMessagePhone ] = useState('');
	//const [ messageEmail, setMessageEmail ] = useState('');
	const [ messageSubject, setMessageSubject ] = useState('');
	const [ messageText, setMessageText ] = useState('');

	const buttonTheme = useTheme({ colorIndex: data.settings.buttonThemeColorIndex > -1 ? data.settings.buttonThemeColorIndex : 1 });
	const backgroundTheme = useTheme({ colorIndex: data.settings.backgroundThemeColorIndex > -1 ? data.settings.backgroundThemeColorIndex : 4 });

	const handleValidSubmit = () => {
		const message = `Dear ${data.content
			.recipientName}%0d%0a%0d%0a${messageText}%0d%0a%0d%0aYours Sincerely%0d%0a${messageName}%0d%0a${messagePhone}%0d%0a%0d%0a`;

		window.location.href = `mailto:${data.content.recipientEmail}?subject=${messageSubject}&body=${message}`;

		//Clear message form
		setMessageName('');
		setMessagePhone('');
		//const setMessageEmail('');
		setMessageSubject('');
		setMessageText('');
	};

	return (
		<Fragment>
			{data.content.recipientEmail &&
			data.content.recipientName && (
				<div className="contact-form">
					<h3>Send us a message</h3>
					<AvForm className="send-message p-3" onValidSubmit={handleValidSubmit} autoComplete="off" style={backgroundTheme.containerStyles}>
						<AvField
							name="name"
							type="text"
							placeholder="Your Name"
							className="mb-2"
							value={messageName}
							onChange={(e) => setMessageName(e.target.value)}
							validate={{ required: true }}
						/>
						<AvField
							name="phone"
							type="text"
							placeholder="Your Telephone Number"
							className="mb-2"
							value={messagePhone}
							onChange={(e) => setMessagePhone(e.target.value)}
							validate={{ required: true }}
						/>

						{/* <AvField
							name="email"
							type="text"
							placeholder="Your Email Address"
							className="mb-2"
							value={messageEmail}
							onChange={(e) => setMessageEmail(e.target.value)}
							validate={{ required: true, email: true }}
						/> */}

						<AvField
							name="subject"
							type="text"
							placeholder="Message Subject"
							className="mb-2"
							value={messageSubject}
							onChange={(e) => setMessageSubject(e.target.value)}
							validate={{ required: true }}
						/>

						<AvField
							name="message"
							type="textarea"
							placeholder="How can we help?"
							className="mb-2"
							value={messageText}
							onChange={(e) => setMessageText(e.target.value)}
							validate={{ required: true }}
						/>

						<Button style={buttonTheme.containerStyles}>SEND</Button>
					</AvForm>
				</div>
			)}
		</Fragment>
	);
};

ContactForm.propTypes = {
	data: PropTypes.object.isRequired
};
