import { useContext } from 'react';
import { TemplatesContext } from '../../template-context';

export const useStyle = () => {
	const { website } = useContext(TemplatesContext);
	const settings = website.content.siteComponents.header.settings;
	const sharedStatus = website.content.siteComponents.header.shared;
	const sharedContent = website.content.siteComponents.shared.settings;

	return {
		themeColorIndex: settings.color > -1 ? settings.color : 1,
		menuShowOnTop: settings.menuShowOnTop > -1 ? settings.menuShowOnTop : false,
		socialLinksColorIndex:
			typeof sharedStatus.socialLinksColor !== 'undefined' && !sharedStatus.socialLinksColor
				? settings.socialLinksColor > -1 ? settings.socialLinksColor : 0
				: sharedContent.socialLinksColor > -1 ? sharedContent.socialLinksColor : 0,
		tapToCallColorIndex: settings.tapToCallColor > -1 ? settings.tapToCallColor : 0,
		logoMaxHeight: `${settings.logoMaxHeight > -1 ? settings.logoMaxHeight : 70}px`
	};
};
