import React from 'react';
import PropTypes from 'prop-types';
import './social-links.css';
import { useTheme } from '../../templates/common/themes';

const SocialLinks = ({
	socialLinkInstagram,
	socialLinkFacebook,
	socialLinkLinkedIn,
	socialLinkTwitter,
	socialLinkPinterest,
	socialLinkYoutube,
	themeColorIndex
}) => {
	const { containerStyles } = useTheme({ colorIndex: themeColorIndex > -1 ? themeColorIndex : 0 });
	return (
		<div className="social-links">
			{socialLinkInstagram && (
				<a className="icon instagram" target="_blank" rel="noopener noreferrer" href={socialLinkInstagram} style={containerStyles} />
			)}
			{socialLinkFacebook && <a className="icon facebook" target="_blank" rel="noopener noreferrer" href={socialLinkFacebook} style={containerStyles} />}
			{socialLinkLinkedIn && <a className="icon linkedin" target="_blank" rel="noopener noreferrer" href={socialLinkLinkedIn} style={containerStyles} />}
			{socialLinkTwitter && <a className="icon twitter" target="_blank" rel="noopener noreferrer" href={socialLinkTwitter} style={containerStyles} />}
			{socialLinkPinterest && (
				<a className="icon pinterest" target="_blank" rel="noopener noreferrer" href={socialLinkPinterest} style={containerStyles} />
			)}
			{socialLinkYoutube && <a className="icon youtube" target="_blank" rel="noopener noreferrer" href={socialLinkYoutube} style={containerStyles} />}
		</div>
	);
};

SocialLinks.propTypes = {
	socialLinkInstagram: PropTypes.string,
	socialLinkFacebook: PropTypes.string,
	socialLinkLinkedIn: PropTypes.string,
	socialLinkTwitter: PropTypes.string,
	socialLinkPinterest: PropTypes.string,
	socialLinkYoutube: PropTypes.string,
	themeColorIndex: PropTypes.number
};

export default SocialLinks;
