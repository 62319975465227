import React from 'react';
import PropTypes from 'prop-types';
import { DefaultHeader } from './defaultHeader';
import { Header2 } from './header2';

export const Header = ({ componentId }) => {
	switch (componentId) {
		case 'a8719e93-32a7-44cc-ad43-0a58b40b6b29':
			return <Header2 />;
		default:
			return <DefaultHeader />;
	}
};

Header.propTypes = {
	componentId: PropTypes.string
};
