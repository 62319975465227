import React, { useContext, Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import './menu-desktop.css';
import TemplateLink from '../../templates/common/template-link';
import { TemplatesContext } from '../../template-context';
import { useTheme } from '../../templates/common/themes';

const MenuDesktop = () => {
	const { website } = useContext(TemplatesContext);
	const [ dropMenuSelectedIndex, setDropMenuSelectedIndex ] = useState(0);
	const [ dropMenuisOpen, setDropMenuIsOpen ] = useState(false);

	const menuColor = useTheme({
		colorIndex: website.content.siteComponents.header.settings.menuColor > -1 ? website.content.siteComponents.header.settings.menuColor : 3
	});
	const menuSeparatorColor = useTheme({
		colorIndex:
			website.content.siteComponents.header.settings.menuSeparatorColor > -1 ? website.content.siteComponents.header.settings.menuSeparatorColor : 0
	});

	useEffect(
		() => {
			setDropMenuSelectedIndex(0);
			setDropMenuIsOpen(false);
		},
		[ website.content.pageTemplates ]
	);

	const setMenuItemOpenState = (index, state) => () => {
		setDropMenuSelectedIndex(index);
		setDropMenuIsOpen(state);
	};

	const menuItemStyle = {
		borderRight: `solid 1px rgb(${menuSeparatorColor.primaryColors.red},${menuSeparatorColor.primaryColors.green},${menuSeparatorColor.primaryColors.blue})`
	};

	const menuFirstItemStyle = {
		borderLeft: `solid 1px rgb(${menuSeparatorColor.primaryColors.red},${menuSeparatorColor.primaryColors.green},${menuSeparatorColor.primaryColors.blue})`,
		...menuItemStyle
	};

	return (
		<Container fluid id="menu-desktop" className="d-none d-md-block" style={menuColor.containerStyles}>
			<Row>
				<Col xs="12">
					<div className="menuLinks">
						{website.content.pageTemplates.map(
							(page, index) =>
								!page.hideLinkInHeader && (
									<Fragment key={index}>
										{/* Single links */}
										{page.type === 'page' ? (
											page.path && (
												<div className="menu-item" style={index == 0 ? menuFirstItemStyle : menuItemStyle}>
													<TemplateLink linkPath={page.path} linkName={page.pageName} />
												</div>
											)
										) : (
											page.pages.length > 0 && (
												/* Group Links */
												<div
													className="menu-item"
													onMouseEnter={setMenuItemOpenState(index, true)}
													onMouseLeave={setMenuItemOpenState(index, false)}
													style={index == 0 ? menuFirstItemStyle : menuItemStyle}
												>
													<div>{page.name}</div>
													<ListGroup
														className={`multi-page-menu-item ${dropMenuSelectedIndex === index &&
															dropMenuisOpen &&
															'd-block'} d-none`}
														onClick={setMenuItemOpenState(index, false)}
													>
														{page.pages.map((item, index) => (
															<ListGroupItem key={index} style={menuColor.containerStyles}>
																<TemplateLink linkPath={item.path} linkName={item.pageName} />
															</ListGroupItem>
														))}
													</ListGroup>
												</div>
											)
										)}
									</Fragment>
								)
						)}
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default MenuDesktop;
